import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store


import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue,
  Fn_ExportExcel,
  Fn_AddEditAwait
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert"

import {
  AvForm,
  AvField, AvRadioGroup, AvRadio
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import '../Masters/datatables.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { API_HELPER } from "helpers/ApiHelper";

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as XLSX from 'xlsx';
import Loader from "pages/loader";


function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
class PageList_PPWalletTran extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      formData: {},
      success_msg: false,
      dynamic_title: "",
      dynamic_description: "",
      company: [],
      page: 1,
      sizePerPage: 10,
      productData: [],
      RReason: '',
      F_RetailerComission: 0,
      F_DistributorComission: 0,
      F_SDistributorComission: 0,
      F_MDistributorComission: 0,
      Charges: 0,
      commissionmodal: false,
      success_dlg: false,
      confirm_undorefund: false,
      title: '',
      UTR: '',
      loadings: false,
      otpModal: false,
      otp: '',
      tempStateResp: '',
      tempMerchantCode: '',
      tempRefId: ''
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "PP KYC Report";
    this.modalTitle = "Audit Attendance";
    this.rtPage_Add = "add-customermaster";
    this.rtPage_Edit = "edit-customermaster";
    this.API_URL = "PPKycTran/0/token";
    this.API_URL1 = "WalletMoneyRefund/AgentRegistration";
    this.API_URL2 = "WalletMoneyRefundOtp/AgentRegistration";


    //Event Binding

    this.btnSave_onClick = this.btnSave_onClick.bind(this);
   
    this.exportToExcel = this.exportToExcel.bind(this);
 
  }

  exportToExcel = () => {

    // let vformData = new FormData();

    // vformData.append("FromDate", this.state.FromDate);
    // vformData.append("ToDate",  this.state.ToDate);

    // Fn_ExportExcel(this.obj, { arguList: { id: 0, formData: vformData } }, "DMRExcel/0/token", "DMRTransaction", true);

    const obj = JSON.parse(sessionStorage.getItem("authUser"));



    const orderAndFilter = (arr) => {
      return arr.map((item) => {

        if (obj.isAdmin) {
          return {
            Date: item.Date,
            TransactionCode: item.TransactionCode,
            Sender: item.Sender,
            AccountHolderName: item.AccountHolderName,
            AccountNo: item.AccountNo,
            IFSC: item.IFSC,
            Amount: item.Amount,
            Charges: item.Charges,
            Mode: item.Mode,
            UTR: item.UTR,
            TransactionStatus: item.TransactionStatus,
            RefundDate: item.RefundDate,
            Remarks: item.Remarks,
            UserName: item.UserName,
            F_DistributorCommission: item.F_DistributorCommission,
            F_SuperDistributorCommission: item.F_SuperDistributorCommission,
            F_MasterDistributorCommission: item.F_MasterDistributorCommission,
            DistributorTDS: item.DistributorTDS,
            SDistributorTDS: item.SDistributorTDS,
            MDistributorTDS: item.MDistributorTDS,
            DistName: item.DistName,
            SDistName: item.SDistName,
            MDistName: item.MDistName
          };
        }

        else {

          return {
            Date: item.Date,
            TransactionCode: item.TransactionCode,
            Sender: item.Sender,
            AccountHolderName: item.AccountHolderName,
            AccountNo: item.AccountNo,
            IFSC: item.IFSC,
            Amount: item.Amount,
            Charges: item.Charges,
            Mode: item.Mode,
            UTR: item.UTR,
            TransactionStatus: item.TransactionStatus,
            RefundDate: item.RefundDate,
            Remarks: item.Remarks

          };

        }
      });
    };

    // Apply the order and filter function to your original array
    const orderedAndFilteredArray = orderAndFilter(this.state.productData);


    const worksheet = XLSX.utils.json_to_sheet(orderedAndFilteredArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'DMRExcel');
    XLSX.writeFile(workbook, `DMRExcel.xlsx`);


  };


 



  componentDidMount() {
 

  }





  btnSave_onClick(event, formData) {

    this.setState({ productData: [{}], loadings: true })


    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    this.setState({ FromDate: formData.FromDate, ToDate: formData.ToDate, productData: [] })

    let vformData = new FormData();
    vformData.append("Id", obj.uid);
    vformData.append("FromDate", formData.FromDate);
    vformData.append("ToDate", formData.ToDate);
    vformData.append("F_UserType", formData.F_UserType == '' || formData.F_UserType == undefined ? 0 : formData.F_UserType);
    vformData.append("Mode", formData.ModeId == '' || formData.ModeId == undefined ? 0 : formData.ModeId);
    vformData.append("F_MemberMaster", formData.F_RetailerId == '' || formData.F_RetailerId == undefined ? 0 : formData.F_RetailerId);
    vformData.append("Status", formData.StatusId == '' || formData.StatusId == undefined ? 0 : formData.StatusId);
    vformData.append("DmrBank", formData.BankId == '' || formData.BankId == undefined ? 0 : formData.BankId);

    Fn_GetReport(this.obj, { arguList: { id: obj.uid, formData: vformData } }, this.API_URL, "productData", true);


  }






 

  render() {

    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    const filteredData = this.state.productData.filter(row => row.F_TransactionStatusCode != 4);

   

    const sumCharges = filteredData.reduce((total, row) => total + Number(row.Kyc_charges), 0);

    // Calculate the total count of filtered rows
    

    const columns = [{


      dataField: 'Date',
      text: 'Date/Time',
      sort: true,
      footer: columnData => (
        <div>

        </div>
      )
    }, {
      dataField: 'Message',
      text: 'Message',
      sort: true,
      footer: columnData => (
        <div>

        </div>
      )
    },
    {
      dataField: 'Merchant_code',
      text: 'Merchant_code',
      sort: true,
      footer: columnData => (
        <div>

        </div>
      )

    },
    {
      dataField: 'Kyc_charges',
      text: 'Charges',
      sort: true,
      footer: columnData => (
        <div>
          {sumCharges}
        </div>
      )
    }


    ];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.productData.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];


    // Select All Button operation
    const selectRow = {
      mode: 'checkbox'
    }

    const { SearchBar } = Search;

    return (




      <>

        <React.Fragment>

          <div className="page-content">
            {this.state.loadings ? <Loader /> : null}

            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
              <Row>
                <Col lg="14">
                  <Card>
                    <CardBody>

                      <div className="wizard clearfix">
                        <div className="content clearfix">

                          <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>

                            <Row>
                              <Col lg="12">
                             
                                <Row>
                                  <Col sm="1" className="mb-3">
                                    <label htmlFor="firstName" className="col-form-label"> From Date</label>
                                  </Col>
                                  <Col sm="3" className="mb-3">
                                    <AvField name="FromDate" label="" value={this.state.formData.FromDate == undefined ? getCurrentDate() : this.state.formData.FromDate} placeholder="From Date" errorMessage="Select Date " validate={{ required: { value: true } }} type="date" className="form-control" />
                                  </Col>

                                  <Col sm="1" className="mb-3">
                                    <label htmlFor="DateofBirth" className="col-form-label">To Date</label>
                                  </Col>
                                  <Col sm="3" className="mb-3">
                                    <AvField name="ToDate" label="" value={this.state.formData.ToDate == undefined ? getCurrentDate() : this.state.formData.ToDate} placeholder="To Date" errorMessage="Select Date " validate={{ required: { value: true } }} type="date" className="form-control" />
                                  </Col>

                                  <Col sm="3" className="mb-3">
                                    <Button
                                      type="submit"
                                      color="primary"

                                      className="mr-1 waves-effect waves-light"
                                    >
                                      View
                                    </Button>





                                    <Button
                                      type="button"
                                      color="primary"
                                      style={{ marginLeft: 10 }}
                                      onClick={this.exportToExcel}
                                      className="mr-1 waves-effect waves-light"
                                    >
                                      Excel
                                    </Button>
                                  </Col>


                                </Row>






                              </Col>
                            </Row>



                          </AvForm>

                        </div>







                      </div>



                    </CardBody>
                  </Card>



                </Col>
              </Row>

              <Row>

                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory({
                          ...pageOptions,
                          sizePerPageList: [
                            { text: '25', value: 25 },
                            { text: '50', value: 50 },
                            { text: '100', value: 100 },
                          ],
                        })}
                        keyField='id'
                        columns={columns}
                        data={this.state.productData}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField='id'
                            columns={columns}
                            data={this.state.productData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>

                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>

                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}

                                      />

                                    </div>
                                  </Col>
                                </Row>

                                {this.state.productData.length > 0 ?

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row> : null}
                              </React.Fragment>
                            )
                            }
                          </ToolkitProvider>
                        )
                        }</PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>






            </Container>





          </div>
        </React.Fragment>



      </>
    );
  }
}
export default compose(container)(PageList_PPWalletTran);
