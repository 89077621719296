import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let matchingMenuItem = null
    const ul = document.getElementById("navigation")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  render() {


    const obj = JSON.parse(sessionStorage.getItem("authUser"));


    if (obj.isAdmin== true ){
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/dashboard"
                      // onClick={e => {
                      //   e.preventDefault()
                      //   this.setState({ isDashboard: !this.state.isDashboard })
                      // }}
                      // to="/dashboard"
                    >
                      <i className="bx bx-home-circle me" />
                      {this.props.t("Dashboard")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    {/* <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                      <Link to="/dashboard" className="dropdown-item">
                        {this.props.t("Default")}
                      </Link>
                     
                    </div> */}
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ uiState: !this.state.uiState })
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-tone me" />
                      {this.props.t("Master")}{" "}
                      <div className="arrow-down" />
                    </Link>
                    {/* <div
                      className={classname(
                        "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                        { show: this.state.uiState }
                      )}
                    > */}
                     <div
                      className={classname("dropdown-menu", {
                        show: this.state.appState,
                      })}
                    >
                    
                          <div>
                          <Link className="dropdown-item" to="/masters-membermaster">
                            {this.props.t("Member Master")}
                          </Link>
                          <Link className="dropdown-item" to={"/memberservice"}>
                    {this.props.t("Member Service")}
                  </Link>

                  {obj.IsStaff ? null :

                  <>
                          <Link className="dropdown-item" to="/masters-ledgermaster">
                    {this.props.t("Ledger Master")}
                  </Link>
                  <Link  className="dropdown-item"to="/masters-planmaster">
                    {this.props.t("Plan Master")}
                  </Link>
                  <Link className="dropdown-item" to="/add-plan">
                    {this.props.t("Plan Add")}
                  </Link>
                  <Link className="dropdown-item" to="/bankupload">
                    {this.props.t("Bank Data Upload")}
                  </Link>
                  <Link className="dropdown-item" to="/masters-bankmaster">
                    {this.props.t("Bank Master")}
                  </Link>
                  <Link className="dropdown-item" to="/transactioncodemaster">
                    {this.props.t("Fund Transfer Status Code ")}
                  </Link>
                  <Link className="dropdown-item" to="/ifscswap">
                    {this.props.t("IFSC Swap")}
                  </Link>
                  <Link className="dropdown-item" to={"/masters-rechargeoperator"}>
                    {this.props.t("Recharge Operator")}
                  </Link>
                  
                  
                  <Link className="dropdown-item" to={"/users"}>
                    {this.props.t("User Master")}
                  </Link> </> }
                          </div>
                       
                    </div>
                  </li>


                  {obj.IsStaff ? null :

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ appState: !this.state.appState })
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-customize me" />
                      {this.props.t("API Manage")} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.appState,
                      })}
                    >
                          <Link className="dropdown-item" to={"/masters-rechargeapi"}>
                        {this.props.t("Recharge API")}
                      </Link>


                        <Link className="dropdown-item" to="/smsapi">
                          {this.props.t("SMS API")}
                        </Link>

                        <Link className="dropdown-item" to="/psonboarding">
                          {this.props.t("PS Onboarding")}
                        </Link>

                        <Link className="dropdown-item" to="/createcsp">
                          {this.props.t("Indo Nepal Create CSP")}
                        </Link>

                        {/* <Link className="dropdown-item" to="/balancecheck">
                          {this.props.t("Balance Check")}
                        </Link> */}

                        <Link className="dropdown-item" to="/apilogs">
                          {this.props.t("API Logs")}
                        </Link>
                    
                     
                    
                    </div>
                  </li>

                    }

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({
                          componentState: !this.state.componentState,
                        })
                      }}
                    >
                      <i className="bx bx-collection me" />
                      {this.props.t("Transaction")}{" "}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.componentState,
                      })}
                    >
                      
                        
                      
                          <Link className="dropdown-item" to="/add-Balancedetails">
                    {this.props.t("Balance Add")}
                  </Link>
                  <Link className="dropdown-item" to={"/voucherslist"}>
                    {this.props.t("Manage Voucher")}
                  </Link>

                  <Link className="dropdown-item" to={"/accounttypeschemelist"}>
                    {this.props.t("Account Type Scheme")}
                  </Link>

                  <Link className="dropdown-item" to={"/PPwalletreport"}>
                    {this.props.t("PP Wallet Report")}
                  </Link>

                  <Link className="dropdown-item" to={"/ppkyctran"}>
                    {this.props.t("PP KYC Report")}
                  </Link>

                  <Link className="dropdown-item" to={"/ppbenverifytran"}>
                    {this.props.t("PP Ben Verify Report")}
                  </Link>

                  <Link className="dropdown-item" to={"/accounttypeschemeinterestlist"}>
                    {this.props.t("Set Interest")}
                  </Link>
                  <Link className="dropdown-item" to={"/Fdmaster"}>
                    {this.props.t("Fd Master")}
                  </Link>
                  <Link className="dropdown-item" to={"/loanmaster"}>
                    {this.props.t("Loan Master")}
                  </Link>
                  <Link className="dropdown-item" to={"/loanreport"}>
                                                        {this.props.t("Loan Report")}
                                                      </Link>

                                                      <Link className="dropdown-item" to={"/loandetails"}>
                                                        {this.props.t("Loan Details")}
                                                      </Link>

                                                      <Link className="dropdown-item" to={"/loanemi"}>
                                                        {this.props.t("Loan EMI")}
                                                      </Link>

                                                      <Link className="dropdown-item" to={"/loanledger"}>
                                                        {this.props.t("Loan Ledger")}
                                                      </Link>
                         
                       
                    
                  
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ extraState: !this.state.extraState })
                      }}
                    >
                      <i className="bx bx-file me" />
                      {this.props.t("Reports")}{" "}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.extraState,
                      })}
                    >

                              <Link className="dropdown-item" to={"/LedgerDetails"}>
                                {this.props.t("Ledger Report")}
                              </Link>
                              <Link className="dropdown-item" to={"/memberbalance"}>
                    {this.props.t("Member Balance Report")}
                  </Link>

                  <Link className="dropdown-item" to={"/topretailer"}>
                    {this.props.t("Top Retailer")}
                  </Link>
                              <Link className="dropdown-item" to={"/dmrreport"}>
                                {this.props.t("Fund Transfer Report")}
                              </Link>
                              <Link className="dropdown-item" to={"/indonepalreport"}>
                                {this.props.t("Indo Nepal Report")}
                              </Link>
                              <Link className="dropdown-item" to={"/rechargetran"}>
                                {this.props.t("Recharge Report")}
                              </Link>
                              <Link className="dropdown-item" to={"/aepsreport"}>
                                {this.props.t("AEPS Report")}
                              </Link>
                              <Link className="dropdown-item" to={"/tdsreportforadmin"}>
                                {this.props.t("TDS MAIN Report")}
                              </Link>
                              <Link className="dropdown-item" to={"/updateutrlist"}>
                                {this.props.t("Update UTR")}
                              </Link>
                              <Link className="dropdown-item" to={"/fundrequestreport"}>
                                {this.props.t("Fund Report")}
                              </Link>
                              <Link className="dropdown-item" to={"/verificationreport"}>
                                {this.props.t("Verification Report")}
                              </Link>

                              <Link className="dropdown-item" to={"/bankledger"}>
                                {this.props.t("Bank Ledger")}
                              </Link>

                              <Link className="dropdown-item" to={"/statementexcel"}>
                              {this.props.t("All Statement Excel")}
                            </Link>


                             

                              
                              <Link className="dropdown-item" to={"/cashledger"}>
                                {this.props.t("Cash Ledger")}
                              </Link>

                                  <Link className="dropdown-item" to={"/comissionreport"}>
                                                                    {this.props.t("Comission Report")}
                                                                  </Link>
                              <Link className="dropdown-item" to={"/accountstatementadmin"}>
                                {this.props.t("Account Statement")}
                              </Link>
                    </div>
                  </li>





                  
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ extraState: !this.state.extraState })
                      }}
                    >
                      <i className="bx bx-file me" />
                      {this.props.t("Help")}{" "}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.extraState,
                      })}
                    >


                  <Link className="dropdown-item" to="GlobalOptions">
                    {this.props.t("Global Options")}
                    </Link>

                    <Link className="dropdown-item" to={"/tickets"}>
                                            {this.props.t("Ticket")}
                                          </Link>
                                
                              
                    </div>
                  </li>
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
                        }

                        else if (obj.roleid == 1) {
                          return (
                            <React.Fragment>
                              <div className="topnav">
                                <div className="container-fluid">
                                  <nav
                                    className="navbar navbar-light navbar-expand-lg topnav-menu"
                                    id="navigation"
                                  >
                                    <Collapse
                                      isOpen={this.props.menuOpen}
                                      className="navbar-collapse"
                                      id="topnav-menu-content"
                                    >
                                      <ul className="navbar-nav">
                                      <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/dashboard"
                      // onClick={e => {
                      //   e.preventDefault()
                      //   this.setState({ isDashboard: !this.state.isDashboard })
                      // }}
                      // to="/dashboard"
                    >
                      <i className="bx bx-home-circle me" />
                      {this.props.t("Dashboard")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    {/* <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                      <Link to="/dashboard" className="dropdown-item">
                        {this.props.t("Default")}
                      </Link>
                     
                    </div> */}
                  </li>
                      
                                        <li className="nav-item dropdown">
                                          <Link
                                            to="/#"
                                            onClick={e => {
                                              e.preventDefault()
                                              this.setState({ uiState: !this.state.uiState })
                                            }}
                                            className="nav-link dropdown-toggle arrow-none"
                                          >
                                            <i className="bx bx-tone me" />
                                            {this.props.t("Master")}{" "}
                                            <div className="arrow-down" />
                                          </Link>
                                          {/* <div
                                            className={classname(
                                              "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                                              { show: this.state.uiState }
                                            )}
                                          > */}
                                           <div
                                            className={classname("dropdown-menu", {
                                              show: this.state.appState,
                                            })}
                                          >
                                          
                                                <div>
                                                <Link className="dropdown-item" to={"/profile/"+obj.F_MemberMaster}>
                                                  {this.props.t("Profile")}
                                                </Link>
                                                </div>
                                             
                                          </div>
                                        </li>
                      
                                      
                      
                                        <li className="nav-item dropdown">
                                          <Link
                                            to="/#"
                                            className="nav-link dropdown-toggle arrow-none"
                                            onClick={e => {
                                              e.preventDefault()
                                              this.setState({
                                                componentState: !this.state.componentState,
                                              })
                                            }}
                                          >
                                            <i className="bx bx-collection me" />
                                            {this.props.t("Transaction")}{" "}
                                            <div className="arrow-down" />
                                          </Link>
                                          <div
                                            className={classname("dropdown-menu", {
                                              show: this.state.componentState,
                                            })}
                                          >
                                            
                                              
                                            <Link className="dropdown-item" to="/add-Balancedetails">
                                              {this.props.t("Balance Add")}
                                            </Link>
                                      
                                               
                                            <Link className="dropdown-item" to="fundrequest">{this.props.t("Fund Request")}</Link>
                                         <Link className="dropdown-item" to={"/fundrequestreport"}>
                                            {this.props.t("Fund Report")}
                                          </Link>
                                          
                                        
                                          </div>
                                        </li>
                      
                                        <li className="nav-item dropdown">
                                          <Link
                                            className="nav-link dropdown-toggle arrow-none"
                                            to="/#"
                                            onClick={e => {
                                              e.preventDefault()
                                              this.setState({ extraState: !this.state.extraState })
                                            }}
                                          >
                                            <i className="bx bx-file me" />
                                            {this.props.t("Reports")}{" "}
                                            <div className="arrow-down" />
                                          </Link>
                                          <div
                                            className={classname("dropdown-menu", {
                                              show: this.state.extraState,
                                            })}
                                          >
                                                
                                                <Link className="dropdown-item" to={"/distaccountstatement"}>
                                              {this.props.t("Account Statement")}
                                            </Link>

                                            {/* <Link className="dropdown-item" to={"/comissionreport"}>
                                              {this.props.t("Comission Report")}
                                            </Link> */}
                                                      
                                                
                                          </div>
                                        </li>
                      
                                        
                                        <li className="nav-item dropdown">
                                          <Link
                                            className="nav-link dropdown-toggle arrow-none"
                                            to="/#"
                                            onClick={e => {
                                              e.preventDefault()
                                              this.setState({ extraState: !this.state.extraState })
                                            }}
                                          >
                                            <i className="bx bx-file me" />
                                            {this.props.t("Help")}{" "}
                                            <div className="arrow-down" />
                                          </Link>
                                          <div
                                            className={classname("dropdown-menu", {
                                              show: this.state.extraState,
                                            })}
                                          >
                                        
                                                    
                                          </div>
                                        </li>
                                      </ul>
                                    </Collapse>
                                  </nav>
                                </div>
                              </div>
                            </React.Fragment>
                          )
                                              }


                                              else if (obj.roleid == 2) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="topnav">
                                                      <div className="container-fluid">
                                                        <nav
                                                          className="navbar navbar-light navbar-expand-lg topnav-menu"
                                                          id="navigation"
                                                        >
                                                          <Collapse
                                                            isOpen={this.props.menuOpen}
                                                            className="navbar-collapse"
                                                            id="topnav-menu-content"
                                                          >
                                                            <ul className="navbar-nav">
                                                            <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/dashboard"
                      // onClick={e => {
                      //   e.preventDefault()
                      //   this.setState({ isDashboard: !this.state.isDashboard })
                      // }}
                      // to="/dashboard"
                    >
                      <i className="bx bx-home-circle me" />
                      {this.props.t("Dashboard")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    {/* <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                      <Link to="/dashboard" className="dropdown-item">
                        {this.props.t("Default")}
                      </Link>
                     
                    </div> */}
                  </li>
                                            
                                                              <li className="nav-item dropdown">
                                                                <Link
                                                                  to="/#"
                                                                  onClick={e => {
                                                                    e.preventDefault()
                                                                    this.setState({ uiState: !this.state.uiState })
                                                                  }}
                                                                  className="nav-link dropdown-toggle arrow-none"
                                                                >
                                                                  <i className="bx bx-tone me" />
                                                                  {this.props.t("Master")}{" "}
                                                                  <div className="arrow-down" />
                                                                </Link>
                                                                {/* <div
                                                                  className={classname(
                                                                    "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                                                                    { show: this.state.uiState }
                                                                  )}
                                                                > */}
                                                                 <div
                                                                  className={classname("dropdown-menu", {
                                                                    show: this.state.appState,
                                                                  })}
                                                                >
                                                                
                                                                      <div>
                                                                      <Link className="dropdown-item" to={"/profile/"+obj.F_MemberMaster}>
                                                                        {this.props.t("Profile")}
                                                                      </Link>
                                                                      </div>
                                                                   
                                                                </div>
                                                              </li>
                                            
                                                            
                                            
                                                              <li className="nav-item dropdown">
                                                                <Link
                                                                  to="/#"
                                                                  className="nav-link dropdown-toggle arrow-none"
                                                                  onClick={e => {
                                                                    e.preventDefault()
                                                                    this.setState({
                                                                      componentState: !this.state.componentState,
                                                                    })
                                                                  }}
                                                                >
                                                                  <i className="bx bx-collection me" />
                                                                  {this.props.t("Transaction")}{" "}
                                                                  <div className="arrow-down" />
                                                                </Link>
                                                                <div
                                                                  className={classname("dropdown-menu", {
                                                                    show: this.state.componentState,
                                                                  })}
                                                                >
                                                                  
                                                                    
                                                                  <Link className="dropdown-item" to="/add-Balancedetails">
                                                                    {this.props.t("Balance Add")}
                                                                  </Link>
                                                            
                                                                  <Link className="dropdown-item" to="fundrequest">{this.props.t("Fund Request")}</Link>
                                         <Link className="dropdown-item" to={"/fundrequestreport"}>
                                            {this.props.t("Fund Report")}
                                          </Link>
                                                                   
                                                                
                                                              
                                                                </div>
                                                              </li>
                                            
                                                              <li className="nav-item dropdown">
                                                                <Link
                                                                  className="nav-link dropdown-toggle arrow-none"
                                                                  to="/#"
                                                                  onClick={e => {
                                                                    e.preventDefault()
                                                                    this.setState({ extraState: !this.state.extraState })
                                                                  }}
                                                                >
                                                                  <i className="bx bx-file me" />
                                                                  {this.props.t("Reports")}{" "}
                                                                  <div className="arrow-down" />
                                                                </Link>
                                                                <div
                                                                  className={classname("dropdown-menu", {
                                                                    show: this.state.extraState,
                                                                  })}
                                                                >
                                                                      
                                                                      <Link className="dropdown-item" to={"/distaccountstatement"}>
                                                                    {this.props.t("Account Statement")}
                                                                  </Link>
                      
                                                                  {/* <Link className="dropdown-item" to={"/comissionreport"}>
                                                                    {this.props.t("Comission Report")}
                                                                  </Link> */}
                                                                            
                                                                      
                                                                </div>
                                                              </li>
                                            
                                                              
                                                              <li className="nav-item dropdown">
                                                                <Link
                                                                  className="nav-link dropdown-toggle arrow-none"
                                                                  to="/#"
                                                                  onClick={e => {
                                                                    e.preventDefault()
                                                                    this.setState({ extraState: !this.state.extraState })
                                                                  }}
                                                                >
                                                                  <i className="bx bx-file me" />
                                                                  {this.props.t("Help")}{" "}
                                                                  <div className="arrow-down" />
                                                                </Link>
                                                                <div
                                                                  className={classname("dropdown-menu", {
                                                                    show: this.state.extraState,
                                                                  })}
                                                                >
                                                              
                                                                          
                                                                </div>
                                                              </li>
                                                            </ul>
                                                          </Collapse>
                                                        </nav>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                )
                                                                    }




                                                                    else if (obj.roleid == 3) {
                                                                      return (
                                                                        <React.Fragment>
                                                                          <div className="topnav">
                                                                            <div className="container-fluid">
                                                                              <nav
                                                                                className="navbar navbar-light navbar-expand-lg topnav-menu"
                                                                                id="navigation"
                                                                              >
                                                                                <Collapse
                                                                                  isOpen={this.props.menuOpen}
                                                                                  className="navbar-collapse"
                                                                                  id="topnav-menu-content"
                                                                                >
                                                                                  <ul className="navbar-nav">
                                                                                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/dashboard"
                      // onClick={e => {
                      //   e.preventDefault()
                      //   this.setState({ isDashboard: !this.state.isDashboard })
                      // }}
                      // to="/dashboard"
                    >
                      <i className="bx bx-home-circle me" />
                      {this.props.t("Dashboard")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    {/* <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                      <Link to="/dashboard" className="dropdown-item">
                        {this.props.t("Default")}
                      </Link>
                     
                    </div> */}
                  </li>
                                                                  
                                                                                    <li className="nav-item dropdown">
                                                                                      <Link
                                                                                        to="/#"
                                                                                        onClick={e => {
                                                                                          e.preventDefault()
                                                                                          this.setState({ uiState: !this.state.uiState })
                                                                                        }}
                                                                                        className="nav-link dropdown-toggle arrow-none"
                                                                                      >
                                                                                        <i className="bx bx-tone me" />
                                                                                        {this.props.t("Master")}{" "}
                                                                                        <div className="arrow-down" />
                                                                                      </Link>
                                                                                      {/* <div
                                                                                        className={classname(
                                                                                          "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                                                                                          { show: this.state.uiState }
                                                                                        )}
                                                                                      > */}
                                                                                       <div
                                                                                        className={classname("dropdown-menu", {
                                                                                          show: this.state.appState,
                                                                                        })}
                                                                                      >
                                                                                      
                                                                                            <div>
                                                                                            <Link className="dropdown-item" to={"/profile/"+obj.F_MemberMaster}>
                                                                                              {this.props.t("Profile")}
                                                                                            </Link>
                                                                                            </div>
                                                                                         
                                                                                      </div>
                                                                                    </li>
                                                                  
                                                                                  
                                                                  
                                                                                    <li className="nav-item dropdown">
                                                                                      <Link
                                                                                        to="/#"
                                                                                        className="nav-link dropdown-toggle arrow-none"
                                                                                        onClick={e => {
                                                                                          e.preventDefault()
                                                                                          this.setState({
                                                                                            componentState: !this.state.componentState,
                                                                                          })
                                                                                        }}
                                                                                      >
                                                                                        <i className="bx bx-collection me" />
                                                                                        {this.props.t("Transaction")}{" "}
                                                                                        <div className="arrow-down" />
                                                                                      </Link>
                                                                                      <div
                                                                                        className={classname("dropdown-menu", {
                                                                                          show: this.state.componentState,
                                                                                        })}
                                                                                      >
                                                                                        
                                                                                          
                                                                                        <Link className="dropdown-item" to="/add-Balancedetails">
                                                                                          {this.props.t("Balance Add")}
                                                                                        </Link>
                                                                                        <Link className="dropdown-item" to="fundrequest">{this.props.t("Fund Request")}</Link>
                                         <Link className="dropdown-item" to={"/fundrequestreport"}>
                                            {this.props.t("Fund Report")}
                                          </Link>
                                                                                           
                                                                                         
                                                                                      
                                                                                    
                                                                                      </div>
                                                                                    </li>
                                                                  
                                                                                    <li className="nav-item dropdown">
                                                                                      <Link
                                                                                        className="nav-link dropdown-toggle arrow-none"
                                                                                        to="/#"
                                                                                        onClick={e => {
                                                                                          e.preventDefault()
                                                                                          this.setState({ extraState: !this.state.extraState })
                                                                                        }}
                                                                                      >
                                                                                        <i className="bx bx-file me" />
                                                                                        {this.props.t("Reports")}{" "}
                                                                                        <div className="arrow-down" />
                                                                                      </Link>
                                                                                      <div
                                                                                        className={classname("dropdown-menu", {
                                                                                          show: this.state.extraState,
                                                                                        })}
                                                                                      >
                                                                                            
                                                                                            <Link className="dropdown-item" to={"/distaccountstatement"}>
                                                                                          {this.props.t("Account Statement")}
                                                                                        </Link>
{/*                                             
                                                                                        <Link className="dropdown-item" to={"/comissionreport"}>
                                                                                          {this.props.t("Comission Report")}
                                                                                        </Link> */}
                                                                                                  
                                                                                            
                                                                                      </div>
                                                                                    </li>
                                                                  
                                                                                    
                                                                                    <li className="nav-item dropdown">
                                                                                      <Link
                                                                                        className="nav-link dropdown-toggle arrow-none"
                                                                                        to="/#"
                                                                                        onClick={e => {
                                                                                          e.preventDefault()
                                                                                          this.setState({ extraState: !this.state.extraState })
                                                                                        }}
                                                                                      >
                                                                                        <i className="bx bx-file me" />
                                                                                        {this.props.t("Help")}{" "}
                                                                                        <div className="arrow-down" />
                                                                                      </Link>
                                                                                      <div
                                                                                        className={classname("dropdown-menu", {
                                                                                          show: this.state.extraState,
                                                                                        })}
                                                                                      >
                                                                                    
                                                                                                
                                                                                      </div>
                                                                                    </li>
                                                                                  </ul>
                                                                                </Collapse>
                                                                              </nav>
                                                                            </div>
                                                                          </div>
                                                                        </React.Fragment>
                                                                      )
                                                                                          }






                                                                                          else if (obj.roleid == 4) {
                                                                                            return (
                                                             <React.Fragment>
                                                               <div className="topnav">
                                                                 <div className="container-fluid">
                                                                   <nav
                                                                     className="navbar navbar-light navbar-expand-lg topnav-menu"
                                                                     id="navigation"
                                                                   >
                                                                     <Collapse
                                                                       isOpen={this.props.menuOpen}
                                                                       className="navbar-collapse"
                                                                       id="topnav-menu-content"
                                                                     >
                                                                       <ul className="navbar-nav">
                                                                       <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/dashboard"
                      // onClick={e => {
                      //   e.preventDefault()
                      //   this.setState({ isDashboard: !this.state.isDashboard })
                      // }}
                      // to="/dashboard"
                    >
                      <i className="bx bx-home-circle me" />
                      {this.props.t("Dashboard")} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    {/* <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                      <Link to="/dashboard" className="dropdown-item">
                        {this.props.t("Default")}
                      </Link>
                     
                    </div> */}
                  </li>
                                                       
                                                                         <li className="nav-item dropdown">
                                                                           <Link
                                                                             to="/#"
                                                                             onClick={e => {
                                                                               e.preventDefault()
                                                                               this.setState({ uiState: !this.state.uiState })
                                                                             }}
                                                                                                              className="nav-link dropdown-toggle arrow-none"
                                                           >
                                                             <i className="bx bx-tone me" />
                                                             {this.props.t("Master")}{" "}
                                                             <div className="arrow-down" />
                                                           </Link>
                                                           {/* <div
                                                             className={classname(
                                                               "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                                                               { show: this.state.uiState }
                                                             )}
                                                           > */}
                                                            <div
                                                             className={classname("dropdown-menu", {
                                                               show: this.state.appState,
                                                             })}
                                                           >
                                                           
                     <div>
                     <Link className="dropdown-item" to={"/profile/"+obj.F_MemberMaster}>
                       {this.props.t("Profile")}
                     </Link>
                     {obj.IsDMR ? 
                      <Link className="dropdown-item" to={"/remitter"}>
                      {this.props.t("Fund Transfer")}
                    </Link> : null}

                    {obj.IsIndoNepal ? 
                    <Link className="dropdown-item" to={"/indonepal"}>
                      {this.props.t("Indo Nepal")}
                    </Link> : null}

                    {obj.IsAEPS ?
                    <>
                      <Link className="dropdown-item" to={"/aepsregistration"}>
                      {this.props.t("AEPS Authenticate")}
                    </Link>
                    <Link className="dropdown-item" to={"/aeps"}>
                      {this.props.t("AEPS")}
                    </Link> </>: null}

                       <Link className="dropdown-item" to={"/psonboarding/"+obj.F_MemberMaster}>
                          {this.props.t("PS Onboarding")}
                        </Link>

                    {obj.IsRecharge ? 
                    <Link className="dropdown-item" to={"/recharge"}>
                      {this.props.t("Recharge")}
                    </Link>
                    :  null}
                    
                     </div>
                  
                                                           </div>
                                                         </li>
                                       
                                                       
                                       
                                                  
                                                                        
                                                   <li className="nav-item dropdown">
                                                    <Link
                                                      className="nav-link dropdown-toggle arrow-none"
                                                      to="/#"
                                                      onClick={e => {
                                                        e.preventDefault()
                                                        this.setState({ report: !this.state.report })
                                                      }}
                                                    >
                                                      <i className="bx bx-file me" />
                                                      {this.props.t("Reports")}{" "}
                                                      <div className="arrow-down" />
                                                    </Link>
                                                    <div
                                                      className={classname("dropdown-menu", {
                                                        show: this.state.report,
                                                      })}
                                                    >
                                                          
                                                          <Link className="dropdown-item" to={"/accountstatement"}>
                                                        {this.props.t("Account Statement")}
                                                      </Link>
           
                                                      <Link className="dropdown-item" to={"/comissionreport"}>
                                                        {this.props.t("Comission Report")}
                                                      </Link>

                                                      {/* {obj.IsDMR ?  */}
                                                      <Link className="dropdown-item" to={"/dmrreport"}>
                                                        {this.props.t("Fund Transfer Report")}
                                                      </Link> 
                                                      {/* : null} */}

                                                      {/* {obj.IsIndoNepal ?  */}
                                                      <Link className="dropdown-item" to={"/indonepalreport"}>
                                                        {this.props.t("Indo Nepal Report")}
                                                      </Link> 
                                                      {/* : null} */}

                                                      {/* {obj.IsRecharge ? */}
                                                      <Link className="dropdown-item" to={"/rechargetran"}>
                                                        {this.props.t("Recharges")}
                                                      </Link>
                                                      {/* : null} */}

                                                      {/* {obj.IsAEPS ? */}
                                                      <Link className="dropdown-item" to={"/aepsreport"}>
                                                        {this.props.t("AEPS Report")}
                                                      </Link>


                                                      <Link className="dropdown-item" to={"/loanreport"}>
                                                        {this.props.t("Loan Report")}
                                                      </Link>
                                                      <Link className="dropdown-item" to={"/PPwalletreport"}>
                                                        {this.props.t("PP Wallet Report")}
                                                      </Link>


                                                      {/* : null}     */}
                                                          
                                                    </div>
                                                                                                          </li>
                                                                                        
                                        
                                        <li className="nav-item dropdown">
                                          <Link
                                            className="nav-link dropdown-toggle arrow-none"
                                            to="/#"
                                            onClick={e => {
                                              e.preventDefault()
                                              this.setState({ help: !this.state.help })
                                            }}
                                          >
                                            <i className="bx bx-file me" />
                                            {this.props.t("Help")}{" "}
                                            <div className="arrow-down" />
                                          </Link>
                                          <div
                                            className={classname("dropdown-menu", {
                                              show: this.state.help,
                                            })}
                                          >
                                         <Link className="dropdown-item" to="fundrequest">{this.props.t("Fund Request")}</Link>
                                         <Link className="dropdown-item" to={"/fundrequestreport"}>
                                            {this.props.t("Fund Report")}
                                          </Link>
                                          <Link className="dropdown-item" to={"/tickets"}>
                                            {this.props.t("Raise Ticket")}
                                          </Link>
                                          <Link className="dropdown-item" to={"/loanmaster"}>
                    {this.props.t("Apply Loan")}
                  </Link>
                                                    
                                          </div>
                                        </li>
                                                                                                        </ul>
                                                                                                      </Collapse>
                                                                                                    </nav>
                                                                                                  </div>
                                                                                                </div>
                                                                                              </React.Fragment>
                                                                                            )
                                                                                                                }
                      


  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Navbar))
