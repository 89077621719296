export const API_WEB_URLS = {
// BASE: "https://testapi.rsevakendra.in/api/V1/",
  // BASE: "https://687c-103-211-43-215.ngrok-free.app/api/V1/",
  // BASE1: "https://c667-103-211-43-215.ngrok-free.app/api/V1/",
  // BASE1: "https://localhost:44362/api/V1/",
  //IMAGEBASE: "https://localhost:44362`/",
  // BASE: "https://localhost:44362/api/V1/",
  //BASE: "http://localhost:8080/api/V1/",
  BASE: "https://pro.rsevakendra.in/api/V1/",
 IMAGEBASE: "https://pro.rsevakendra.in/",
  LOGIN: "Authentication/Login",
  //
  MASTER: "Masters",
  MEMBEREDITDATA : "Masters/0/token/MemberEdit/Id",
  BALANCEEDITDATA : "Masters/0/token/BalanceEdit/Id",
  SMSAPIEDITADD: "Masters/0/token/SMSAPIEdit/Id",
  CUSTOMER: "CustomerMaster",
  MEMBERMASTER: "MemberMaster",

  RECHARGEAPI: "RechargeAPI",

  RECHARGEOPERATOR: "RechargeOperator",

  BALANCEADD: "BalanceAdd",
  PLANMASTERL: "PlanMasterL",
  USERMASTER: "AddUserMaster",
  SENDERMASTER: "SenderMaster",
  BENEFICIARYMASTER: "BeneficiaryMaster",
  VERIFY: "Verify",
  SMSAPIADD: "SMSAPI",
  BANKADD: "BankMaster",
  COMPANY : "CompanyMaster",
  RemitterNoCheck : "RemitterNoCheck",
  AgentRegistration : "AgentRegistration",
  SHIFT : "ShiftMaster",
  RAWDATA : "InsertRawData",
  GLOBALOPTIONS : "UpdateGlobalOptions",
  UPDATEMEMBERSERVICE : "UpdateServiceMember"

};
